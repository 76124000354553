<template>
  <div>
    <b-card-actions
        ref="cardA"
        title="How to subscribe facebook pages?"
        no-actions
        no-body
    >
      <b-card class="mb-2" no-body>
        <div class="mx-1">
          <b-row>
            <b-col cols="12">
              <ol>
                <li>Click "Facebook Login" button.</li>
                <li><b>If Facebook ask for OTP. Enter OTP to log in then close the Facebook pop up. And click "Facebook Login" button again.</b></li>
                <li>In Facebook popup. Select all pages you want to give the permissions.</li>
                <li>All pages those you selected from Facebook should be display in the "Pages" table.</li>
                <li>Click page's subscribe button. If it show message "Successfully subscribed 'page name'" then your already subscribed the page.</li>
              </ol>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="2">
              <HeroButtonAction variant="primary" type="button" @click="doOpenManual"><feather-icon icon="BookOpenIcon" /> Manual</HeroButtonAction>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-actions>

    <b-card-actions
        ref="cardB"
        title="Pages"
        no-actions
        no-body
    >
      <b-card class="mb-2" no-body>
        <!-- !! Table Header -->
        <div class="mx-1">
          <b-row>
            <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
              <HeroInputText
                  id="filter"
                  v-model="tableConfig.filter"
                  placeholder="Search"
                  @input="doTableFilter(100)"
              />
            </b-col>
            <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
            </b-col>
            <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2">
              <HeroButtonAction type="button" variant="primary" @click="doFacebookLogin">
                <feather-icon icon="FacebookIcon" /> Facebook Login
              </HeroButtonAction>
            </b-col>
          </b-row>
        </div>

        <!-- !! Table -->
        <b-row class="my-1">
          <b-col cols="12">
            <b-table
                show-empty
                striped
                sticky-header="100%"
                :responsive="true"
                :items="items"
                :fields="tableConfig.fields"
                :per-page="0"
                :sort-by="tableConfig.sortBy"
                :sort-direction="tableConfig.sortDirection"
                :no-sort-reset="true"
                :no-local-sorting="true"
            >
              <template #cell(action)="data">
                <HeroButtonAction variant="primary" type="button" @click="doSubscribePage(data.item.name, data.item.id, data.item.access_token)">Subscribe</HeroButtonAction>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-card-actions>
  </div>
</template>

<script>
import moment from 'moment'
import Toastification from '@/services/Toastification'
import {
  BCard,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'

export default {
  components: {
    BCard,
    BCardActions,
    HeroButtonAction,
    HeroInputText,
  },
  data() {
    return {
      pages: [],
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Page name',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Page Id',
            key: 'id',
            sortable: false,
          },
          {
            label: 'Action',
            key: 'action',
            stickyColumn: false,
          },
        ],
      },
      nextPage: '',
    }
  },
  mounted() {
    // ทำการเรียก Facebook SDK
    console.log(process.env.VUE_APP_LEAD_GENERATION_ID_APP_ID)
    console.log(process.env.VUE_APP_LEAD_GENERATION_SDK_VERSION)

    window.fbAsyncInit = function() {
      FB.init({
        appId:   process.env.VUE_APP_LEAD_GENERATION_ID_APP_ID,
        cookie:  true,
        xfbml:   true,
        version: process.env.VUE_APP_LEAD_GENERATION_SDK_VERSION,
      });

      // ถ้าเชื่อมต่อ Facebook SDK ไม่ได้ คำสั่งด้านล่างจะแสดงข้อผิดพลาดบน Console
      FB.AppEvents.logPageView();
    };

    if(window.FB) {
      window.fbAsyncInit()
    }

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()

          this.items = this.pages.filter(page => {
            const name   = page.name.toLowerCase()
            const filter = this.tableConfig.filter.toLowerCase()

            // search จะส่งตำแหน่ง index ของคำที่ค้นหากลับมาให้ ถ้าหาไม่เจอจะส่ง -1 กลับมา
            return name.search(filter) > -1;
          });
        }
      }, timeout)
    },

    doFacebookLogin() {
      const _this = this

      window.FB.login(response => {
        if (response && (response.authResponse === undefined || response.authResponse === null)) {
          // return false
        }

        if (response.error) {
          _this.$toast(Toastification.getContentError(response.error.message))
        }

        _this.items = []
        _this.doLoadPages('/me/accounts?limit=50&fields=access_token,id,name')
      }, {
        scope: 'business_management, leads_retrieval, ads_management, pages_manage_ads, pages_manage_metadata, pages_read_engagement, pages_read_user_content'
      })
    },

    doLoadPages(url) {
      const _this = this
      this.$refs.cardB.showLoading = true

      window.FB.api(url, response => {
        if (response.error) {
          _this.$toast(Toastification.getContentError(response.error.message))
          return false
        }

        _this.items.push(...response.data)
        _this.pages = _this.items

        if (response.paging.next) {
          _this.nextPage = response.paging.next
        } else {
          _this.nextPage = ''
        }
      })

      this.$refs.cardB.showLoading = false
    },

    doSubscribePage (pageName, pageId, pageAccessToken) {
      const payload = {
        access_token: pageAccessToken,
        subscribed_fields: ['leadgen'],
      }

      window.FB.api(`/${pageId}/subscribed_apps`, 'post', payload, response => {
        if (response.success === true) {
          this.$toast(Toastification.getContentSuccess(`Successfully subscribed ${pageName}`))
        }

        if (response.error) {
          this.$toast(Toastification.getContentError(response.error.message))
        }
      })
    },

    doOpenManual() {
      window.open('https://docs.google.com/presentation/d/10ZKSkhsNlnp4gLRC5nHeRj_iezPhzPQZEy34sGFQvNY/edit#slide=id.g621d124c9d_0_0"', '_blank')
    }
  },
  watch: {
    nextPage (url) {
      if (url === '') {
        return false
      }

      this.doLoadPages(url)
    }
  }
}
</script>
